<template>
    <div class="Pills">
      <ul>
        <li v-for="(pill, i) in pills"  @click="filter(pill, i)" :key="i"><a href="javascript:void(0)" :class="i === active ? 'active' : ''">{{ upperFirst(pill.deal_type) }} <b>{{ '(' + pill.item_count + ')' }})</b></a> </li>
      </ul>
    </div>
</template>

<script>
  import _ from 'lodash';
    export default {
        props: {
          pills: Array,
        },
      data() {
        return {
          active: 0
        }
      },
      methods: {
        filter(pill, i) {
          this.active = i;
          this.$emit('change', pill);
        },
        upperFirst(value){
          return _.upperFirst(value)
        }

      },
    };
</script>
