<template>
  <div class="Hm-hero-slider">
    <VueSlickCarousel v-bind="settings" >
      <div class="Hm-hero-slide item" v-for="(tile, i) in slider.tiles" :key="i">
        <span>
          <v-image :src="tile.image" ratio="2" alt="Hm-Tp-Slider" />
        </span>
        <div class="Hm-hero-overlay">
          <div class="Hm-hero-overlay-inner">
            <h3> {{ tile.display_name }} </h3>
            <p> {{ tile.description}} </p>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: "HeroSlider",
  props: ['slider'],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        "dots": true,
        "autoplay": true,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.1,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrow":false
      },
    }
  },
}
</script>

<style scoped>

</style>