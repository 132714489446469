<template>
  <div v-if="top_picks" class="carousel_section  defualt-slider">
    <div class="head">
      <h2 class="left title"> {{ top_picks.title }} </h2>
      <router-link class="right view_link" to="/experience-listing/activities">View All</router-link>
    </div>
    <div class="car_slid_wrap">
      <VueSlickCarousel v-bind="settings2"  v-if="top_picks">
        <div class="cr_tab item" v-for="(tile, i) in top_picks.tiles"  :key="i"  v-ripple>
          <div class="img_holder">
            <v-image height="auto" width="100%" ratio="2" :src="tile.image" alt="#"></v-image>
            <div @click="move_to_detail(tile)" class="cr_img_overlay">
              <div class="table">
                <div class="table-cell">
                  <div v-if="tile.distance" class="cr_btm_dist_sec clearfix">
                    <mark class="left gredient2"> {{ tile.distance }} </mark>
                  </div>
                </div>
              </div>
            </div>
            <div class="heart_overlay">
              <div class="table">
                <div class="table-cell">
                  <div @click="$store.dispatch('wishlist/toggle_wishlist_item', tile)" class="cr_tp_off_sec">
                    <i :class="get_wishlist_class(tile) ? 'btn cr_fav_icon right b-block' : 'btn cr_fav_icon_fill right b-block'"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text_holder "  @click="move_to_detail(tile)">
            <h2 class="fix_height">{{ tile.title }} </h2>
            <div class="reviews_price_wrap">
              <star-rating :rating="tile.rating" :text="tile.rating_count_text"></star-rating>
              <div class="price_dis_sec right">
                <del class="old_price"> {{ tile.currency }} {{ tile.rr_price }}</del>
                <span class="price"> <i class="ins_confirm_icon"></i>{{ tile.currency }} <b class="cur"></b>{{ tile.price }}</span>
                <span class="dis_price"> {{ tile.price_off_text}} </span>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import commonMixins from "@/mixins/commonMixins";
export default {
  props: ['top_picks'],
  name: "TopPicksSlider",
  mixins: [commonMixins],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings2: {
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrow":false
      },
    }
  },
}
</script>
<style>
.heart_overlay {
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  text-align: center;
  background: none;
}

.heart_overlay .table-cell {
  width: 100%;
  padding: 16px;
  vertical-align: top;
}
</style>