<template>

  <div class="carousel_section get-ins-slider" v-if="just_for_you">
    <div class="head">
      <h2 class="left title"> {{ just_for_you.title }} </h2>
      <span class="right view_link" @click="move_to_experience_listing" v-ripple>See More</span>
    </div>
    <div class="car_slid_wrap ">
      <VueSlickCarousel v-bind="settings3" >
        <div @click="set_filter(tile)" class="cr_tab hdr-gredient item" v-for="(tile, i) in just_for_you.tiles" :key="i"  v-ripple>
          <div class="img_holder">
            <img :src="tile.other_place_bg_image" class="img_responsive"  />
            <div class="cr_img_overlay">
              <div class="table">
                <div class="table-cell">
                  <div class="get-ins-content">
                    <span> <img :src="tile.other_place_icon" alt="#"> </span>
                    <label>{{ tile.name }} </label>
                    <p> {{ tile.item_count_text }} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import commonMixins from "@/mixins/commonMixins";
export default {
  name: "JustForYouSlider",
  props: ['just_for_you'],
  mixins: [commonMixins],
  components: {
    VueSlickCarousel
  },

  data() {
    return {
      settings3: {
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 2,
        "slidesToScroll": 2,
        "arrow":false
      },
    }
  },
  methods: {
    set_filter: function (tile){
      this.$store.dispatch('filters/set_preset_filters', tile)
      this.$router.push('/experience-listing/activities')
    },
    move_to_experience_listing(){
      this.$store.dispatch('filters/reset_filters');
      this.$router.push('/experience-listing/activities');
    },

  },
}
</script>

<style scoped>

</style>