<template>
  <div v-if="spotlight" class="carousel_section Spotlight-slider">
    <div class="head">
      <h2 class="left title"> {{ spotlight.title }} </h2>
      <span class="right view_link "  v-ripple>
        <router-link to="/experience-listing/attractions">See More</router-link>
      </span>
    </div>
    <div class="car_slid_wrap">
      <VueSlickCarousel v-bind="settings4" >
        <div @click="move_to_detail(tile)" class="cr_tab item" v-for="(tile, i) in spotlight.tiles" :key="i"  v-ripple>
          <div class="img_holder">
            <v-image :src="tile.image" ratio="2" alt="#"></v-image>
            <div class="cr_img_overlay">
              <div class="table">
                <div class="table-cell">
                  <div v-if="tile.distance" class="cr_btm_dist_sec clearfix">
                    <mark class="left gredient2"> {{ tile.distance }} </mark>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text_holder ">
            <h2 class=" "> {{ tile.display_name }} </h2>
            <label v-if="tile.product_count">{{ tile.product_count }}</label>
            <label v-else>0 Things to do </label>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import commonMixins from "../../mixins/commonMixins";

export default {
  name: "SpotlightSlider",
  props: {
    spotlight: Object
  },
  components: {
    VueSlickCarousel
  },
  mixins: [commonMixins],
  data() {
    return {
      settings4: {
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrow":false
      },
    }
  },
}
</script>

<style scoped>

</style>