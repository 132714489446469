// @vue/component
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import SearchFixed from "../../components/SearchFixed/SearchFixed";
import VueSlickCarousel from 'vue-slick-carousel'
import HeroSlider from "@/components/Sliders/HeroSlider";
import SpotlightSlider from "@/components/Sliders/SpotlightSlider";
import InspirationSlider from "@/components/Sliders/InspirationSlider";
import TopPicksSlider from "@/components/Sliders/TopPicksSlider";
import JustForYouSlider from "@/components/Sliders/JustForYouSlider";
import Pills from "@/components/Pills/Pills";
import ActivityCard from "@/components/ActivityCard/ActivityCard"
import GetawaysTile from "../../components/GetawaysTile";
import _ from 'lodash';
import $ from 'jquery'
import commonMixins from "@/mixins/commonMixins";
export default {
    name: 'HomeScreen',
    components: {
        SearchComponent,
        VueSlickCarousel,
        SearchFixed,
        HeroSlider,
        SpotlightSlider,
        InspirationSlider,
        TopPicksSlider,
        JustForYouSlider,
        Pills,
        ActivityCard,
        GetawaysTile
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
            show_fixed: false,
            show_menu: false,
            search_term: '',
            is_searching: false,
            category_id: null,
            deal_type: null,
            loaded: false,

        }
    },

    computed: {
        selected_destination(){
            return this.$store.getters['dashboard/get_selected_destination'];
        },
        top_picks(){
            return this.get_section('tours');
        },
        just_for_you(){
            return this.get_section('other_places')
        },
        inspirations(){
            return  this.get_section("itineraries");
        },
        spotlight(){
            return this.get_section("attractions")
        },
        slider(){
            return this.get_section("home_slider");
        },
        getaways(){
            return this.get_section('getaways_cuckoo')
        },
        things_to_do(){
            return this.$store.getters['dashboard/get_search_results'];
        },
        pills: function(){
            return this.$store.getters['dashboard/get_search_pills'];
        },
        user: function(){
            return this.$store.getters['auth/getUser']
        },
        profile: function(){
            if (this.user){
                return this.user.customer_profile;
            }
        },

    },
    watch: {
        search_term(){
            if (this.search_term)
                this.handleSearch(this.search_term)
        },
    },
    created () {
        this.get_home_data()
    },
    mounted() {
        $("#home_content").scroll(() => {
            let scroll = $("div#home_content").scrollTop();
            this.show_fixed = scroll > 412 || this.is_searching;
        });

        // let is_access_travel_enable =  this.$store.getters['dashboard/get_home_data'];
    },
    methods: {
        search: function (){
            let common_data = this.get_form_data()
            common_data.set('query', this.term);
            if (this.deal_type)
                common_data.set('deal_type', this.deal_type);
            if (this.category_id)
                common_data.set('category_id', this.category_id);
            this
                .$store
                .dispatch('dashboard/get_home_outlets', common_data)
                .then(() => {
                    this.search_term = this.term;
                })
                .catch(error => {
                    console.log(error)
                });
        },
        cancel_search: function (){
            this.show_fixed = false;
            this.is_searching = false;
            this.search_term = '';
        },
        updateSearchCriteria: function (pill){
            let params = pill.api_params;
            this.category_id = params.category_id;
            this.deal_type = params.deal_type;
            this.search();
        },
        handleSearch: _.debounce(function (term) {
            this.term = term;
            this.category_id = '';
            this.deal_type = '';
            this.show_fixed = true;
            this.is_searching = true;
            if (!this.term){
                return
            }
            this.$store.dispatch('dashboard/empty_search_results')
            this.search()
        }, 3000),
        get_section(name){
            let sections = this.$store.getters['dashboard/get_home_data'];
            if (!sections)
                return null;
            sections = sections['home_sections'];
            return sections.find((section) => {
                if (section.identifier === name)
                    return section;
            });
        },
        get_home_data(){
            let common_data = this.get_form_data_with_auth()
            this.$store.dispatch('dashboard/get_home_data', common_data)
                .then(() => {
                    this.loaded = true;
                })
                .catch(error => {
                    console.log(error)
                });
        },
    }
}
