<template>
  <div class="cr_tab item" v-ripple>
    <div class="img_holder">
      <v-image   v-if="data.image_url" :src="data.image_url" ratio="2" alt="#"></v-image>
      <v-image v-else-if="data.image" :src="data.image" ratio="2" alt="#"></v-image>
      <v-image v-else-if="data.hero_urls" :src="data.hero_urls[0]" ratio="2" alt="#"></v-image>
      <div @click="emitClick" class="cr_img_overlay">
        <div class="table">
          <div class="table-cell">
            <div class="cr_tp_off_sec">
              <span class="cr_special_offer" v-if="data.is_special_offer">
                <img src="../../assets/images/special-offer.png" alt="#">
              </span>

            </div>
            <div v-if="data.distance" class="cr_btm_dist_sec clearfix">
              <mark class="left gredient2"> {{ data.distance }} </mark>
            </div>
          </div>
        </div>
      </div>
      <div  class="heart_overlay">
        <div class="table">
          <div class="table-cell">
            <div class="cr_tp_off_sec">
              <div v-if="subtype === 'planner'"  @click="$store.dispatch('planner/push_to_plan_draft', data)">
                <i :class="get_planning_class(data) ? 'btn cr_plus_icon right b-block' : 'btn cr_minus_icon right b-block'"></i>
              </div>
              <div v-else @click="$store.dispatch('wishlist/toggle_wishlist_item', data)" >
                <i :class="get_wishlist_class(data) ? 'btn cr_fav_icon right b-block' : 'btn cr_fav_icon_fill right b-block'"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text_holder " @click="emitClick">
      <h2 class="fix_height" v-if="data.name">{{ data.name }} </h2>
      <h2 class="fix_height"  v-else-if="data.title">{{ data.title }} </h2>
      <h2 class="fix_height"  v-else-if="data.display_name">{{ data.display_name }} </h2>
      <div class="reviews_price_wrap">
        <div class="rating_sec_outer left">
          <div class="rating">
            <span class="rating_star" :style="'width:'+data.rating *20 +'%'"></span>
          </div>
          <p> {{ data.rating_count_text }}</p>
        </div>
        <div v-if="data.item_type === 'viator_tour'" class="price_dis_sec right">
          <del class="old_price"> {{ data.currency }} {{ data.rr_price }}</del>
          <span class="price"> <i class="ins_confirm_icon"></i>{{ data.currency }} <b class="cur"></b>{{ data.price }}</span>
          <span class="dis_price"> {{ data.price_off_text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapIcon from "../../assets/images/map-icon.png";
import Placeholder from "@/assets/images/placeholder.png"
import commonMixins from "../../mixins/commonMixins";
export default {
  data() {
    return {
      MapIcon,
      Placeholder
    }
  },
  mixins: [commonMixins],
  methods: {
    emitClick: function () {
      this.$emit('clicked', this.data)
    },
    header_click: function (){
      this.$emit('headerClick', this.data)
    }
  },
  props: {
    data: null,
    type: String,
    items: null,
    subtype: {
      default: null,
      type: String
    }
  },
 };
</script>
<style>
.heart_overlay {
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  text-align: center;
  background: none;
}

.heart_overlay .table-cell {
  width: 100%;
  padding: 16px;
  vertical-align: top;
}
</style>