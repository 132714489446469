<template>
  <header class="pg-head-outer Header_outer">
    <div class="hd-searching-sec">
      <div class="topSearch right-btn">
        <div class="fieldWrap">
          <label>
            <input @input="search" ref="search" v-model="search_term" type="text" placeholder="Search" class="focus">
          </label>
          <v-btn disabled class="search-btn"></v-btn>
        </div>
        <v-btn v-if="show_cancel" @click="cancelSearch" class="cancel primary" type="button" name="button">Cancel</v-btn>
        <v-btn v-else @click="cancelSearch" class="cancel primary" type="button" name="button">Cancel</v-btn>
      </div>
    </div>
  </header>
</template>

<script>
    export default {
      props: ['show_cancel', 'text'],
      data() {
        return {
          search_term: '',
        }
      },
      computed: {
          previous_page: function (){
            return this.$store.getters['app/get_previous_page'];
          },
      },
      mounted() {
          this.search_term = this.text;

      },
      methods: {
        search() {
          this.$emit('search', this.search_term);
        },
        cancelSearch(){
          this.search_term = ''
          this.$emit('cancel')
        }
      },
    };
</script>
