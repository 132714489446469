<template>
  <div class="carousel_section trav-insp-slider" v-if="getaway">
    <div class="head">
      <h2 class="left title"> {{ getaway.title }} </h2>
    </div>
    <div class="car_slid_wrap">
      <VueSlickCarousel v-bind="settings5" >
        <div class="cr_tab trav-insp-slid item" @click="openDeepLink(tile)" v-for="(tile, i) in getaway.tiles" :key="i"  v-ripple>
          <div class="img_holder">
            <v-image :src="tile.image" alt="#" ratio="1.8"></v-image>
            <div class="cr_img_overlay">
              <div class="table">
                <div class="table-cell">
                  <button class="gredient2" v-ripple>Explore more</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
       <v-dialog fullscreen v-model="dialog" hide-overlay transition="dialog-bottom-transition">
         <v-card>
           <v-toolbar dark color="primary">
             <v-toolbar-title>Getaways</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-toolbar-items>
               <v-btn dark text @click="dialog = false">Close</v-btn>
             </v-toolbar-items>
           </v-toolbar>
           <v-container>
             <iframe  :src="url" style="position:fixed; top:56px; left:0; bottom:0; right:0; width:100%; height:90%; border:none; margin:0; padding:0; overflow:hidden;">
               Your browser doesn't support iframes
             </iframe>
           </v-container>
         </v-card>
       </v-dialog>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
export default {
  name: "GetawaysTile",
  props: ['getaway'],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      dialog: false,
      url: '',
      settings5: {
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrow":false
      },
    }
  },
  computed: {
    auth_data: function(){
      return this.$store.getters['auth/auth_data']
    },
    home_data(){
      return this.$store.getters['dashboard/get_home_data'];
    }
  },

  methods: {
    openDeepLink: function(tile) {
      let deepLink = this.home_data.access_url
      deepLink = deepLink + `?access_token=${this.home_data.access_token}&member_key=${this.home_data.access_member_id}`;
      this.url = deepLink;
      if(this.home_data.is_access_travel_enable) {
        this.dialog = true;
      }
      else {
        let deepLink = tile.deep_link;
        deepLink = deepLink + `&__t=${this.auth_data.__session_token}&return_url=${location.href}`;
        this.$store.dispatch('app/set_loader', true)
        window.location.href = deepLink;
      }
    },
  }
}
</script>

<style scoped>
  header {
    z-index: 999999
  }
</style>