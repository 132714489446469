<template>
  <div class="topSearch">
    <form>
      <div :class="isFocused ? 'fieldWrap input-expand': 'fieldWrap'">
        <input @focusin="isFocused = true" @focusout="isFocused = false" v-model="value" @keyup="search" type="search" placeholder="Find a place" value="">
        <v-btn class=" search-btn" outlined large type="submit"> </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
    export default {
        props: {
        },
      data() {
        return {
          value: '',
          isFocused: false
        }
      },
      methods: {
        search() {
          this.$emit('searching', this.value);
        }
      },
    };
</script>
