<template>
  <div class="carousel_section trav-insp-slider" v-if="inspirations">
    <div class="head">
      <h2 class="left title"> {{ inspirations.title }} </h2>
    </div>
    <div class="car_slid_wrap">
      <VueSlickCarousel v-bind="settings5" >
        <div class="cr_tab trav-insp-slid item" @click="openDeepLink" v-for="i in 1" :key="i"  v-ripple>
          <div class="img_holder">
            <v-image :src="travelInspire" alt="#" ratio="1.8"></v-image>
            <div class="cr_img_overlay">
              <div class="table">
                <div class="table-cell">
                  <h2> Beat Your boredom </h2>
                  <button class="gredient2" v-ripple>Explore more</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import travelInspire from '../../assets/images/travel-insp-optim.jpg'
export default {
  name: "InspirationSlider",
  props: ['inspirations'],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      travelInspire,
      settings5: {
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrow":false
      },
    }
  },
  methods: {
    openDeepLink: function(){
      this.$router.push('/planner')
    },
  },
}
</script>

<style scoped>

</style>